.bigWrapper {
    text-align: center;
}

.wrapper {
    margin: 2px 0px 42px 0px;
    position: relative;
}

.wrapper.static {
    margin: 0 auto;
    max-width: 300px;
}

.wrapper.studentMode {
    margin: 0 auto;
    max-width: 90%;
}

.wrapper.hasResetButton {
    margin: 2px 0px 12px 0px;
}

.image {
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
    cursor: crosshair;
}

.overlay.static {
    cursor: auto;
}

.overlay.studentMode {
    cursor: auto;
}

.answerRegion {
    background-color: rgba(255, 0, 0, 0.5);
    position: absolute;
    user-select: none;
}

.answerRegion.correct {
    background-color: rgba(8, 155, 8, 0.65);
}

.answerRegion.shouldBeCorrect {
    background-color: rgba(8, 155, 8, 0.25);
}

.resetButton {
    padding: 6px 9px;
    border-radius: 0px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    border-radius: 8px;
    font-size: 18px;
    font-family: var(--font-p-family);
    cursor: pointer;
    margin: 0px 0px 32px 0px;
}