@import '../../util/vars.css';

.buttonWrapper {
    display: block;
    position: relative;
    border: 0;
    background-color: rgba(0,0,0,0);
    color: var(--color-darkgray);
    height: 22px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-family: var(--font-p-family);
    padding: 0px 10px 0px 30px;
    margin: 0px;
    cursor: pointer;
}

.buttonWrapper.light {
    color: var(--color-white);
}

.buttonWrapper.centered {
    padding: 0px;
    margin: 0 auto;
    margin-top: 30px;
}

@media (min-width: 360px) {
    .buttonWrapper {
        padding: 0px 10px 0px 32px;
    }
}

.editIcon {
    position: absolute;
    height: 17px;
    top: 2px;
    left: 6px;
}

.mergeIcon {
    position: absolute;
    width: 16px;
    top: 6px;
    left: 8px;
}

.moveIcon {
    position: absolute;
    height: 11px;
    top: 4px;
    left: 10px;
}

.archiveIcon {
    position: absolute;
    height: 13px;
    top: 4px;
    left: 8px;
}

.resetIcon {
    position: absolute;
    height: 16px;
    top: 3px;
    left: 7px;
}

.playIcon {
    position: absolute;
    height: 14px;
    top: 4px;
    left: 10px;
}

.pauseIcon {
    position: absolute;
    height: 12px;
    top: 4px;
    left: 12px;
}

.endIcon {
    position: absolute;
    height: 16px;
    top: 3px;
    left: 7px;
}

.disabled {
    visibility: hidden;
}