@import '../../util/vars.css';

.assessmentWrapper {
    width: 100% - 28px;
    height: 96px;
    border-radius: 8px;
    border: 1px solid var(--color-gray);
    margin-bottom: 12px;
    padding: 41px 14px 28px 14px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}

.assessmentWrapper.last {
    margin-bottom: 42px
}

.startButton {
    position: absolute;
    top: 10px;
    right: 16px;
    padding: 8px 10px 8px 14px;
    border-radius: 12px;
    color: var(--color-white);
    background-color: var(--color-darkpurple);
    border: none;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    cursor: pointer;
}

.startButton.disabled {
    padding: 8px 14px 8px 14px;
    background-color: var(--color-white);
    color: var(--color-darkpurple);
    cursor: not-allowed;
}

.centerWrapper {
    display: flex;
    align-items: center;
}

.assessmentName {
    font-style: var(--font-p-family);
    font-size: 18px;
    font-weight: 700;
    margin-top: 14px;
    margin-bottom: 8px;
    color: var(--color-darkpurple);
}

.assessmentDescription {
    font-style: var(--font-p-family);
    font-size: 16px;
    height: 64px;
    overflow: hidden;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.28;        /* fallback */
    max-height: 3.84;       /* fallback */
    color: var(--color-darkgray);
}

.assessmentArt {
    width: calc(100% + 6px);
    height: 64px; 
    position: absolute;
    top: -2px;
    left: -3px;
    z-index: -1;
}