@import '../../util/vars.css';

.studentWrapper {
    width: 100% - 22px;
    height: 40px;
    padding: 0px 10px;
    position: relative;
    line-height: 40px;
    border-bottom: 1px solid var(--color-medgray);
    transition: height 0.15s ease-in-out;
    overflow: hidden;
}

.studentWrapper.expanded {
    height: 92px;
}
.studentWrapper.last {
    border-bottom: none;
    margin-bottom: 2px;
}

.studentName {
    font-family: var(--font-p-family);
    font-size: 16px;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Added 41px to every right to make room for percentage */
.queuedIcon {
    height: 22px;
    position: absolute;
    top: 10px;
    right: 131px;
}

.playIcon {
    height: 20px;
    position: absolute;
    top: 10px;
    right: 139px;
}

.pauseIcon {
    height: 16px;
    position: absolute;
    top: 12px;
    right: 142px;
}

.visibilityIcon {
    height: 20px;
    position: absolute;
    top: 10px;
    right: 91px;
}

.actionsIcon {
    height: 30px;
    position: absolute;
    top: 5px;
    right: 2px;
    cursor: pointer;
}

.percentage {
    height: 24px;
    width: 52px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 36px;
    position: absolute;
    font-family: var(--font-p-family);
    font-size: 14px;
    color: var(--color-darkgray);
}

.actionsWrapper {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    background-color: var(--color-gray);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    height: 30px;
    border-radius: 6px;
    column-gap: 12px;
}

@media (max-width: 359px) {
    .smallCollapse {
        display: none
    }
}

@media (max-width: 379px) {
    .actionsWrapper {
        column-gap: 6px;
    }
}

@media (min-width: 460px) {
    .actionsWrapper {
        column-gap: 10%;
    }
}