.wrapper {
    border-top: 1px solid var(--color-gray);
    padding-top: 24px;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    color: var(--color-black);
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.wrapper.lowMargin {
    margin-top: 30px;
}

.copyright {
    padding-left: 32px;
    padding-right: 10px;
    height: 22px;
    color: var(--color-purple);
}

@media(max-width: 570px) {
    .wrapper {
        justify-content: left;
    }
}

.zeroHeight {
    height: 0px;
    overflow: hidden;
}