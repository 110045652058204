.wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
}

.card {
    min-width: 340px;
    flex-basis: calc(50% - 36px);
    max-width: calc(50% - 36px);
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-width: 100%;
    margin-top: 18px;
}

.inner {
    display: block;
    text-align: center;
    min-width: 300px;
}