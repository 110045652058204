.bigWrapper {
    text-align: center;
}

.wrapper {
    margin: 2px 0px 22px 0px;
    position: relative;
}

.wrapper.static {
    margin: 0 auto;
    max-width: 300px;
}

.wrapper.studentMode {
    margin: 0 auto;
    max-width: 90%;
}

.wrapper.hasResetButton {
    margin: 2px 0px 12px 0px;
}

.image {
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
    cursor: crosshair;
}

.overlay.static {
    cursor: auto;
}

.overlay.studentMode {
    cursor: auto;
}

.anchorCircle {
    border: 2px solid var(--color-darkpurple);
    color: var(--color-darkpurple);
    height: 24px;
    width: 24px;
    margin: 2px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    position: absolute;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
    font-family: var(--font-p-family);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    transform: translate(-17px, -17px)
}

.anchorCircle.studentMode {
    cursor: auto;
}

.anchorCircle.static {
    cursor: auto;
}

.anchorCircle.correct {
    background-color: rgba(8, 155, 8, 0.5);
}

.anchorCircle.studentMode {
    background-color: rgba(0, 0, 0, 0);
}

.anchorCircle.correct.studentMode {
    background-color: rgba(238, 255, 0, 0.4);
}

.resetButton {
    padding: 6px 9px;
    border-radius: 0px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    border-radius: 8px;
    font-size: 18px;
    font-family: var(--font-p-family);
    cursor: pointer;
    margin: 0px 0px 18px 0px;
}