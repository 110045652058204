@import '../../util/vars.css';

.centerWrapper {
    display: flex;
    align-items: center;
}

.header {
    background-color: var(--color-purple);
    height: 66px;
    color: var(--color-white);
    font-family: var(--font-h1-family);
    width: calc(100% - 154px);
    padding: 60px 144px 0px 20px;
    align-items: end;
    justify-content: left;
    font-size: 46px;
    border-bottom: 10px solid var(--color-faintpurple);
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.header.own {
    background-color: var(--color-pink);
    border-bottom: 10px solid var(--color-faintpink);
}

.header.friend {
    background-color: var(--color-yellowgreen);
    border-bottom: 10px solid var(--color-faintgreen);
}

@media(max-width: 480px) {
    .header {
        font-size: 30px;
        padding: 76px 144px 0px 20px;
        height: 50px;
    }
}

.buttonBar {
    position: absolute;
    width: fit-content;
    top: 14px;
    right: 16px;
    height: 34px;
    display: flex;
    justify-content: right;
    gap: 8px;
}

.newButton {
    padding: 8px 10px 8px 14px;
    border-radius: 12px;
    color: var(--color-white);
    background-color: var(--color-darkpurple);
    border: none;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    cursor: pointer;
    z-index: 3;
}

.newButton.alt {
    padding: 6px 12px;
    border-radius: 12px;
    color: var(--color-darkpurple);
    background-color: var(--color-white);
    border: 2px var(--color-darkpurple) solid;
}

.newButton.personal {
    color: var(--color-white);
    background-color: var(--color-darkpink);
    border: 2px var(--color-darkpink) solid;
}

.newButton.alt.personal {
    color: var(--color-darkpink);
    background-color: var(--color-white);
    border: 2px var(--color-darkpink) solid;
}

.questions {
    background-color: var(--color-faintpurple);
    position: absolute;
    width: 110px;
    height: fit-content;
    top: 88px;
    right: 0px;
    padding: 6px 10px;
    border-radius: 12px 0px 0px 0px;
    text-align: center;
    font-family: var(--font-p-family);
    color: var(--color-darkpurple);
    font-size: 15px;
}

.questions.own {
    background-color: var(--color-faintpink);
    color: var(--color-pink);
}

.questions.friend {
    background-color: var(--color-faintgreen);
    color: var(--color-yellowgreen);
}

.description {
    padding: 12px 14px;
    font-family: var(--font-p-family);
    color: var(--color-black);
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    line-height: 1.28;        /* fallback */
    max-height: 3.84;       /* fallback */
}

.bottomRow {
    padding: 16px 12px 12px 12px;
    width: calc(100% - 24px);
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.bottomLeft,
.bottomRight {
    /* width: calc(50% - 8px); */
    font-family: var(--font-p-family);
}

.bottomLeft {
    width: calc(45% - 8px);
    display: flex;
    align-items: center;
    color: var(--color-darkgray);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
}

.owner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
}

.bottomRight {
    width: calc(55% - 8px);
    text-align: right;
    text-overflow: ellipsis;
    color: var(--color-darkgray);
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
}

.contentWrapper {
    padding: 12px 20px;
}