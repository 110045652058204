.contentTable {
    border-collapse: collapse; /* Ensure borders between cells are collapsed */
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--font-p-size);
    border: 1px solid var(--color-darkgray); /* Border for the entire table */
}

.contentTable th, .contentTable td {
    border: 1px solid var(--color-darkgray); /* Border for each cell */
    padding: 10px;
    text-align: left;
}

.contentTable thead {
    font-weight: 600;
    background-color: var(--color-purple);
    color: var(--color-white);
}

.contentTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3; /* Optional: for alternate row shading */
}

.cell {
    text-wrap: wrap;
    font-size: 14px;
}

.icon {
    padding: 5px; /* Add padding around each icon */
    font-size: 16px;
}

.iconReal {
    color: var(--color-yellowgreen); /* Color for real_key is true */
}

.iconDemo {
    color: var(--color-yellow); /* Color for demo_key is true */
}

.flagsHeader {
    width: 158px;
}

.smallText {
    font-size: 12px;
    line-height: 1.4;
    text-wrap: wrap;
}

.activeStatus {
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--color-yellowgreen);
    padding: 2px 6px;
}

.trialStatus {
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--color-yellow);
    padding: 2px 6px;
}

.expiredStatus {
    font-weight: bold;
    color: var(--color-white);
    background-color: red;
    padding: 2px 6px;
}

.actionCell {
    width: 180px;
}

.action {
    padding: 4px 8px;
    font-size: 16px;
    border-radius: 12px;
    color: var(--color-white);
    cursor: pointer;
    display: inline-block;
    margin-bottom: 4px;
    border: 0px solid var(--color-white);
}

.action.extend {
    background-color: var(--color-blue);
    margin-right: 6px;
}

.action.impersonate {
    background-color: var(--color-purple);
}