@import '../../util/vars.css';

.topRightButton {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0);
    border: none;
    cursor: pointer;
}

.icon {
    position: absolute;
    height: 32px;
    top: 4px;
    right: 0px;
}