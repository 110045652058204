@import '../../util/vars.css';

.resultWrapper {
    width: 100% - 26px;
    height: fit-content;
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    margin-bottom: 12px;
    /* padding: 13px 13px 13px 46px; */
    position: relative;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
    cursor: pointer;
}

.moreIcon {
    position: absolute;
    top: 14px;
    right: 17px;
    color: var(--color-medgray);
}

.resultName {
    font-family: var(--font-h1-family);
    font-size: 18px;
    font-weight: var(--font-h1-weight);
    margin-bottom: 8px;
    color: var(--color-darkpurple);
    margin-right: 26px;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.summarizedFeedback {
    font-size: 16px;
    font-family: var(--font-p-family);
    color: var(--color-black);
    height: 84px;
    overflow: hidden;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 4;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-height: 1.31;        /* fallback */
    max-height: 5.44;       /* fallback */
}

.submissions {
    position: absolute;
    bottom: 13px;
    right: 13px;
    font-family: var(--font-p-family);
    font-size: 16px;
    font-style: italic;
    color: var(--color-darkgray);
}

.lastResult {
    margin-bottom: 72px;
}

.innerWrapper {
    height: calc(100% - 24px);
    padding: 14px 14px 18px 56px;
}

.submissions_sidebar {
    background-color: var(--color-purple);
    position: absolute;
    top: 0px;
    height: 73px;
    left: 0px;
    width: 42px;
    color: var(--color-white);
    padding: 50px 0px;
    line-height: 1.08;
}

.submissions_sidebar.withFriends {
    background: linear-gradient(-45deg, var(--color-yellowgreen) 50%, var(--color-purple) 50%);
    background-color: unset;
    background-position: 0 -15px;
}

.submission_icon {
    font-size: 28px;
    color: var(--color-white);
    text-align: center;
}

.submission_text {
    font-family: var(--font-h1-family);
    font-size: 16px;
    text-align: center;
}

@media(min-width: 800px) {
    .submissions_sidebar {
        width: 54px;
    }

    .innerWrapper {
        padding: 14px 14px 18px 70px;
    }
    
}