.newGraphWrapper {
    min-height: 40px;
    margin-bottom: 26px;
    margin-right: 11px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.graphLabelBarWrapper {
    display: flex;
    min-width: 100%;
    align-items: center;
}

.newLabel {
    width: 80px;
    border-right: 2px solid var(--color-darkgray);
    color: var(--color-black);
    text-align: right;
    padding: 3px 5px 5px 0px;
    font-size: 14px;
}

.graphPercent {
    width: 48px;
    padding-left: 7px;
    font-family: var(--font-p-family);
    font-size: 14px;
    color: var(--color-purple);
    font-weight: 700;
}

.graphBar {
    width: 100%;
    height: 16px;
    border-top: 1px solid var(--color-darkpurple);
    border-right: 1px solid var(--color-darkpurple);
    border-bottom: 1px solid var(--color-darkpurple);
    background-color: var(--color-purple);
    margin: 2px 0px;
}

.gridWrapper {
    display: grid;
    grid-template-columns: auto 1fr; /* First column (labels) auto width, second column takes the remaining space */
    column-gap: 5px; /* Adds space between the rows */
    row-gap: 4px;
    width: 100%;
}

.gridRow {
    display: contents; /* Let the grid handle the layout without flexbox */
    cursor: pointer;
}

.gridLabel {
    padding: 3px 2px 5px 0px;
    white-space: nowrap; /* Prevents label from breaking into multiple lines */
    text-align: right; /* Align labels to the right */
    width: max-content; /* Ensure label takes up only as much width as it needs */
    justify-self: end;
    font-family: var(--font-h1-family);
}

.gridBar {
    height: 20px; /* Height of the bar */
    color: var(--color-white);
    background-color: var(--color-darkpurple); /* Bar color */
    width: calc(var(--bar-size) - 10px); /* Dynamic bar width based on the bar size (set via inline style) */
    align-items: center;
    display: flex;
    justify-content: right;
    overflow: hidden;
    padding: 3px 5px 4px 5px;
    font-family: var(--font-h1-family);
}

.gridDescriptor {
    text-align: left; /* Align descriptor text to the left */
    font-size: 16px;  /* You can adjust the font size */
    color: var(--color-darkgray);      /* Color of the descriptor text */
    padding-bottom: 8px;
    font-family: var(--font-p-family);
    line-height: 1.2;
}
