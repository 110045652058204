.centered {
    align-content: center;
    width: 100%;
    margin-bottom: 50px;
}

.columnWrapper {
    margin: 0px 16px 74px 16px;
    position: relative;
    text-align: left;
}

@media (min-width: 801px) {
    .columnWrapper {
        margin: 0 auto;
        width: 92%;
        max-width: 800px;
    }

    .columnWrapper.wide {
        max-width: 1200px;
    }
}