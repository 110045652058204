@import '../../util/vars.css';

.drawerWrapper {
    height: 100%;
    width: 420px;
    max-width: 90%;
    position: fixed;
    top: 0;
    right: -420px;
    bottom: 0;
    background-color: var(--color-white);
    z-index: 998;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    transition: right 0.15s ease-in-out;
}

.drawerWrapper.show {
    right: 0;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
}

@media (min-width: 440px) {
    .drawerWrapper {
        max-width: 420px;
    }
}

.header {
    color: var(--color-white);
    height: 60px;
    width: 100% - 16px;
    background-color: var(--color-darkpurple);
    font-family: var(--font-h1-family);
    font-weight: 500;
    font-size: 20px;
    position: relative;
    vertical-align: center;
    line-height: 60px;
    padding-left: 16px;
    /* border-bottom: var(--color-black) 1px solid; */
}

.content {
    margin: 1.2rem 1.2rem 6rem 1.2rem;
}

.screenOverlay {
    position: fixed;
    width: 0%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 997;
    display: block;
    transition: background-color 0.1s ease-in-out;
}

.screenOverlay.show {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.text {
    float: left;
}

.icon {
    position: absolute;
    top: 4px;
    right: 16px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
}