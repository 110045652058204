.quoteCard {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 20px;
    width: calc(100% - 40px);
    margin: 0px auto;
    margin-top: 40px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    background-color: #fff;
  }