.flexRow {
    width: 100%;
    display: grid;
    overflow: hidden;
    grid-auto-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    margin-bottom: 16px;
    padding: 36px 0px;
}

.flexRow.three {
    grid-template-columns: repeat(3, 1fr);
}

.flexRow.two {
    grid-template-columns: repeat(2, 1fr);
    max-width: 780px;
    margin: 0 auto;
}

.flexRow.noSpace {
    padding: 26px 0px 8px 0px;
}

.flexRow.borders {
    border-top: 1px solid var(--color-gray);
    border-bottom: 1px solid var(--color-gray);
}

@media(max-width: 860px) {
    .flexRow.three {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0px;
    }
}

@media(max-width: 750px) {
    .flexRow.two {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 32px;
        margin: 0 auto;
    }
}

@media(max-width: 450px) {
    .flexRow.three {
        grid-template-columns: repeat(1, 1fr);
        margin: 0 auto;
        width: calc(100% - 46px);
        grid-row-gap: 32px;
    }
}