@media (min-width: 818px) {
    .wrapper:not(.noflex) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
        /* flex-wrap: wrap; */
        row-gap: 8px;
        column-gap: 12px;
        justify-content: center;
        margin-bottom: 16px;
    }
}