.groupWrapper {
    background-color: var(--color-lightpurple);
    color: var(--color-white);
    font-family: var(--font-p-family);
    padding: 20px;
    font-size: var(--font-p-size);
    border-radius: 8px;
    width: 100px;
    overflow: hidden;
    cursor: pointer;
}

.groupWrapper.big {
    width: auto;
}

.groupWrapper.none {
    background-color: var(--color-blue);
}

.groupWrapper.disabled {
    background-color: var(--color-gray);
}

.groupWrapper.selected {
    background-color: var(--color-orange);
}

.dataWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
}

.dataRow {
    padding: 0px 0px 4px 0px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
