.conceptOrSkill {
    background-color: var(--color-black);
    padding: 2px 10px 4px 10px;
    margin: 4px 10px 4px 0px;
    border-radius: 8px;
    color: var(--color-white);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
}

@media(min-width: 500px) {
    .conceptOrSkill {
        font-size: 16px;
    }
}

.conceptOrSkillPercentage {
    width: 42px;
    text-align: center;
    margin-right: 8px;
}

.conceptOrSkillText {
    width: calc(100% - 56px);
    padding: 5px 0px;
}

.conceptOrSkill::-webkit-scrollbar {
    display: none;
}

.conceptOrSkill.last {
    margin-bottom: 24px;
}