@import '../../util/vars.css';

.selector {
    border: none;
    border-bottom: 1px solid var(--color-darkgray);
    width: calc(100% + 2px);
    margin-top: 4px;
    margin-bottom: 32px;
    font-size: 18px;
    padding: 6px 6px 6px 8px;
    color: var(--color-darkgray);
}

@media (min-width: 1400px) {
    .selector.alt {
        margin-left: 6px;
        background-color: var(--color-darkpurple);
        border-bottom: 2px solid var(--color-faintpurple);
        color: var(--color-faintpurple);
    }
}

.selector.alt:active {
    outline: 0;
}

.selector.noSpaceAfter {
    margin-bottom: 4px;
}

.selector.hasError {
    margin-bottom: 4px;
}

.errorText {
    text-align: right;
    font-style: italic;
    font-family: var(--font-p-family);
    font-size: 13px;
    margin-bottom: 22px;
    color: var(--color-red);
}

.errorText.noSpaceAfter {
    bottom: 14px;
}