@import '../../util/vars.css';

.screenOverlay {
    position: fixed;
    width: 0%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 997;
    display: block;
    transition: background-color 0.1s ease-in-out;
}

.screenOverlay.show {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.wholeScreenWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;
    visibility: hidden;
}

.wholeScreenWrapper.show {
    display: block;
    visibility: visible;
    align-content: center;
}

.popupWrapper {
    position: relative;
    width: calc(100% - 82px);
    max-width: 700px;
    top: 100%;
    border-radius: 12px;
    height: auto;
    min-height: 110px;
    background-color: var(--color-white);
    z-index: 998;
    padding: 26px 16px 20px 16px;
    border: 1px solid var(--color-darkgray);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
    overflow: scroll;
    align-content: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin: 0 auto;
    color: var(--color-darkgray);
}

.popupWrapper::-webkit-scrollbar {
    display: none;
}

@media (min-width: 1400px) {
    .popupWrapper {
        margin-top: 50px;
    }
}

.popupWrapper.show {
    top: 0px;
}

.titleWrapper {
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    font-family: var(--font-h1-family);
    color: var(--color-darkpurple);
}