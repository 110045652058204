.csvUpload {
    position: relative;
    width: 100%;
    height: 12rem;
    overflow: auto;
    box-sizing: border-box;
    margin-bottom: 82px;
}

.csvUpload.hasError {
    margin-bottom: 6px;
}

.hidden {
    opacity: 0%;
}

.csvUploadLabel {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 2px dashed var(--color-gray);
    color: var(--color-gray);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0px auto;
    flex-direction: column;
    line-height: 0.1rem;
}
  
  .csvUploadLabel .icon {
    font-size: 32px;
  }
  
.csvUploadLabel p {
    font-size: 20px;
}

.resetButton {
    padding: 6px 9px;
    border-radius: 0px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    border-radius: 8px;
    font-size: 18px;
    font-family: var(--font-p-family);
    cursor: pointer;
    margin: 8px 0px 32px 0px;
}

.csvPreview {
    width: 100%;
    text-align: center;
}

.csvPreview p {
    text-align: left;
}

.previewTable {
    border-left: 2px solid var(--color-darkgray);
    border-right: 2px solid var(--color-darkgray);
    border-top: 2px solid var(--color-darkgray);
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
    font-family: var(--font-p-family);
    color: var(--color-darkgray);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    margin-top: 24px;

    th {
        text-align: center;
    }

    th, td {
        border: 1px solid var(--color-darkgray);
        padding: 3px 6px;
        width: 25%;
    }

    td {
        text-overflow: ellipsis;
        overflow-x: hidden;
        text-align: left;
    }

    td.noBottom {
        border-bottom: 0px solid;
        background-color: #F1F1F1;
        border-left: 1px dashed var(--color-darkgray);
        border-right: 1px dashed var(--color-darkgray);
    }
}

.errorSpacer {
    padding-top: 400px;
}