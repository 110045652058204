.inputRowWrapper {
    font-size: 20px;
    font-family: var(--font-p-family);
    padding: 9px 0px;
    color: var(--color-black);
}

.inputRowWrapper.last {
    margin-bottom: 24px;
}

.disabled {
    color: var(--color-gray);
}

.checkboxInput {
    display: none;
}

.label {
    display: flex;
    align-items: center;
}

.labelText {
    padding-left: 10px;
    color: var(--color-black);
    font-size: var(--font-p-size);
}