.draggableCard {
    padding: 10px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid var(--color-darkgray);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
    font-family: var(--font-p-family);
    background-color: var(--color-white);
    cursor: pointer;
    touch-action: none;
    white-space: nowrap;
}