@import '../../util/vars.css';

.inputWrapper {
    position: relative;
}

.input {
    border: 1px solid var(--color-darkgray);
    width: calc(100% - 16px);
    margin-top: 4px;
    margin-bottom: 26px;
    min-height: 120px;
    line-height: 1.3;
    resize: vertical;
    font-size: 18px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    padding: 6px 6px 6px 8px;
    color: var(--color-darkgray);
}

.input:focus {
    outline: none;
}

.input.hasError {
    margin-bottom: 34px;
}

.input.noSpaceAfter {
    margin-bottom: 6px;
}

.input.noSpaceBefore {
    margin-top: 0px;
}

.input.noSpaceAfter.hasError {
    margin-bottom: 326px;
}

.errorText {
    position: absolute;
    bottom: 22px;
    right: 0;
    font-style: italic;
    font-family: var(--font-p-family);
    font-size: 13px;
    color: var(--color-red);
}

.errorText.noSpaceAfter {
    bottom: 14px;
}