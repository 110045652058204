.groupWrapper {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
}

.groupWrapper.fitted {
    margin-bottom: 0px;
}

@media(min-width: 600px) {
    .groupWrapper {
        justify-content: right;
    }
}