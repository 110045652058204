.popupWrapper {
    position: relative;
    width: calc(100% - 36px);
    max-width: 800px;
    border-radius: 12px 12px 0px 0px;
    height: auto;
    min-height: 100%;
    bottom: 0px;
    background-color: var(--color-white);
    /* padding: 34px 16px 28px 16px; */
    border-top: 1px solid var(--color-medgray);
    border-left: 1px solid var(--color-medgray);
    border-right: 1px solid var(--color-medgray);
    transition: top 0.3s ease-in-out;
    overflow: scroll;
    animation: rise-in 0.25s;
    align-content: start;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
    margin: 0 auto;
}

.popupWrapper::-webkit-scrollbar {
    display: none;
}

@media(max-width: 1059px) {
    .popupWrapper{
        border-bottom: 1px solid var(--color-medgray);
        border-radius: 12px 12px 12px 12px;
        height: fit-content;
        min-height: unset;
        margin-bottom: 8px;
    }
}