.columnWrapper {
    flex: 1;
    padding: 0px 15px 0px 30px;
    width: calc(50% - 45px);
    min-height: 100%;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    max-width: 800px;
}

.columnWrapper::-webkit-scrollbar {
    display: none;
}

@media (max-width: 999px) {
    .columnWrapper:not(.stackable) {
        display: none;
        visibility: hidden;
    }
}

.columnWrapper.stackable {
    flex: none;
}

@media (min-width: 610px) {
    .columnWrapper.stackable {
        min-width: 570px;
    }
}

@media (max-width: 609px) {
    .columnWrapper.stackable {
        width: calc(100% - 24px);
        padding: 0px 12px 0px 12px;
    }
}