@import '../../util/vars.css';

.resultWrapper {
    width: 100% - 26px;
    height: 144px;
    border-radius: 8px;
    border: 1px solid var(--color-gray);
    margin-bottom: 12px;
    padding: 13px 13px 13px 46px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}

.resultWrapper.expanded {
    height: auto;
    min-height: 144px;
}

.resultName {
    font-style: var(--font-p-family);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--color-darkpurple);
}

.summarizedFeedback {
    font-style: var(--font-p-family);
    color: var(--color-darkgray);
    font-size: 16px;
    height: 84px;
    overflow: hidden;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 4;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-height: 1.31;        /* fallback */
    max-height: 5.24;       /* fallback */
}

.summarizedFeedback.expanded {
    display: block;
    max-height: fit-content;
    height: auto;
    margin-bottom: 22px;
}

.score {
    position: absolute;
    bottom: 8px;
    right: 13px;
    font-style: var(--font-p-family);
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    color: var(--color-purple);
}

.resultArtWrapper {
    width: 30px;
    position: absolute;
    top: -1px;
    left: 0px;
    bottom: -1px;
    z-index: -1;
}

.resultArt {
    width: 100%;
    height: 100%;
}

.expandIcon {
    height: 22px;
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
}

.lighter {
    color: var(--color-lightpurple);
    font-style: italic;
}