.columnWrapper {
    flex: 1;
    padding: 0px 15px 0px 15px;
    width: calc(50% - 45px);
    min-height: 100%;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    max-width: 800px;
    min-width: 350px;
}

.columnWrapper::-webkit-scrollbar {
    display: none;
}

.columnWrapper.narrow {
    flex: none;
    width: 230px;
}

@media (max-width: 999px) {
    .columnWrapper:not(.stackable) {
        display: none;
        visibility: hidden;
    }
}

/* @media (max-width: 921px) {
    .columnWrapper.stackable {
        display: none;
        visibility: hidden;
    }
} */