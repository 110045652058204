.stimuliWrapper {
    padding: 16px 0px;
    font-family: var(--font-p-family);
    font-size: 16px;
    position: relative;
}

.stimuliWrapper.noTopSpace {
    padding: 0px;
}

.stimuliWrapper.last {
    margin-bottom: 64px;
}

.stimuliWrapper.editMode {
    margin-top: 8px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-medgray);
}

.stimuliWrapper.last.editMode {
    border-bottom: none;
}

.imageWrapper {
    text-align: center;
    margin: 12px 0px;
}

.stimuliImage {
    width: 60%;
    max-width: 400px;
    margin-bottom: 8px;
}

.actionButton {
    position: absolute;
    top: 12px;
    right: 0px;
    padding: 6px 9px;
    border-radius: 12px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    font-size: 16px;
    font-family: var(--font-p-family);
    cursor: pointer;
    z-index: 3;
}

.centerWrapper {
    display: flex;
    align-items: center;
}

.buttonRow {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: right;
    column-gap: 12px;
    margin-bottom: 18px;
}

.inlineActionButton {
    padding: 6px 9px;
    border-radius: 12px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    font-size: 16px;
    font-family: var(--font-p-family);
    cursor: pointer;
    z-index: 3;
}

.inlineActionButton.primary {
    color: var(--color-white);
    background-color: var(--color-purple);
    border: 2px var(--color-purple) solid;
}

@media (max-width: 440px) {
    .hideSmall {
        display: none;
        visibility: hidden;
    }

    .stimuliImage {
        width: 90%;
    }
}

.promptWrapper {
    color: var(--color-black);
}

.stimuliWrapper.withLines {
    border-bottom: 1px solid var(--color-medgray);
}

.stimuliWrapper.withLines.last {
    border-bottom: none;
}

.extraText {
    font-family: var(--font-p-family);
    color: var(--color-medgray);
    text-align: right;
    margin-bottom: 12px;
    padding-right: 6px;
}

.bold {
    font-weight: 700;
}

@media (max-width: 1059px) {
    .stimuliWrapper.ultraSmallEnabled {
        height: 15px;
        overflow: hidden;
        padding: 8px 95px 8px 0px;
        border: 0px solid;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        line-height: 1.28;        /* fallback */
        max-height: 1.28;       /* fallback */
    }

    .stimuliWrapper.ultraSmallEnabled.last {
        margin-bottom: 0px;
    }

    .imageWrapper.ultraSmallEnabled {
        display: none;
        visibility: hidden;
    }

    .actionButton.ultraSmallEnabled {
        top: 2px;
        padding: 3px 5px;
        border-radius: 9px;
        font-size: 14px;
    }
}