@import '../../util/vars.css';

.groupObjectWrapper {
    display: block;
    margin: 0 auto;
}

.reorderWrapper {
    width: 30px;
    height: 88px;
    margin: 0 auto;
    float: left;
    position: relative;
}

.clickableRegion {
    cursor: pointer;
    z-index: 22;
}

.groupWrapper {
    width: 100% - 28px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    margin-bottom: 10px;
    padding: 14px 14px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    transition: height 0.15s ease-in-out;
    background-color: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
}

.groupWrapper.narrower {
    width: calc(100% - 62px);
}

.groupWrapper.expanded {
    height: 106px;
}

.clickableRegion {
    height: 84px;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    left: 0;
    cursor: pointer;
}

.groupName {
    font-family: var(--font-p-family);
    font-size: 18px;
    font-weight: 700;
    margin-top: 3px;
    margin-bottom: 10px;
    color: var(--color-darkpurple);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 296px;
}

.groupCount {
    font-family: var(--font-p-family);
    font-size: 14px;
    /* font-style: italic; */
    color: var(--color-darkgray);
    display: inline-flex;
    width: 120px;
}

@media(max-width: 400px) {
    .groupCount {
        width: 100px;
    }
}

.assessmentCount {
    font-family: var(--font-p-family);
    font-size: 14px;
    /* font-style: italic; */
    color: var(--color-darkgray);
    display: inline-flex;
    width: 190px;
}

@media(max-width: 389px) {
    .assessmentCount {
        display: none;
        visibility: hidden;
    }
}

.actionsIcon {
    height: 22px;
    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 3;
    cursor: pointer;
}

.upIcon {
    height: 22px;
    position: absolute;
    top: 16px;
    left: 0px;
    z-index: 3;
    cursor: pointer;
}

.downIcon {
    height: 22px;
    position: absolute;
    bottom: 16px;
    left: 0px;
    z-index: 3;
    cursor: pointer;
}

.groupArt {
    position: absolute;
    height: 103px;
    top: -13px;
    right: -4px;
    z-index: 3;
}

.actionsWrapper {
    position: absolute;
    top: 90px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 6px;
    height: 28px;
    column-gap: 12px;
    background-color: var(--color-purple);
    border-top-left-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 2px;
}

@media (max-width: 374px) {
    .actionsWrapper {
        column-gap: 0px;
    }
}

@media (min-width: 700px) {
    .groupObjectWrapper {
        min-width: 370px;
    }
}

.last {
    margin-bottom: 26px;
}

.groupWrapper.shake {
    animation: tilt-n-move-shaking 0.2s infinite;
}

@keyframes tilt-n-move-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(3px) }
    50% { transform: translateX(-3px) }
    75% { transform: translateX(3px) }
    100% { transform: translateX(0px) }
}

.gray {
    color: #707070;
}