.imageWrapper {
    text-align: center;
    margin: 12px 0px;
}

.promptWrapper {
    color: var(--color-black);
    font-weight: 700;
}

.stimuliImage {
    width: 60%;
    max-width: 400px;
    margin-bottom: 8px;
}

@media (max-width: 440px) {
/*     .hideSmall {
        display: none;
        visibility: hidden;
    }
 */
    .stimuliImage {
        width: 90%;
    }
}

.stimuliWrapper {
    border-bottom: 1px solid var(--color-medgray);
    margin-bottom: 36px;
    padding-bottom: 10px;
}

.stimuliWrapper.last {
    border-bottom: none;
    margin-bottom: 54px;
}

.stimuliWrapper.noBorder {
    border-bottom: none;
    margin-bottom: 0px;
}