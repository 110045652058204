@import '../../util/vars.css';

.inputWrapper {
    position: relative;
}

.inputWrapper.half {
    width: calc(50% - 8px);
    display: inline-flex;
}

.inputWrapper.half.right {
    width: calc(50% - 8px);
    margin-left: 16px;
}

.input {
    border: none;
    border-bottom: 1px solid var(--color-darkgray);
    width: calc(100% - 16px);
    margin-top: 4px;
    margin-bottom: 32px;
    font-size: 18px;
    padding: 6px 6px 6px 8px;
    color: var(--color-darkgray);
}

.input:focus {
    outline: none;
}

.input.hasError {
    margin-bottom: 40px;
}

.input.noSpaceAfter {
    margin-bottom: 12px;
}

.input.noSpaceBefore {
    margin-top: 0px;
}

.input.noSpaceAfter.hasError {
    margin-bottom: 32px;
}

.errorText {
    position: absolute;
    bottom: 22px;
    right: 0;
    font-style: italic;
    font-family: var(--font-p-family);
    font-size: 13px;
    color: var(--color-red);
}

.errorText.noSpaceAfter {
    bottom: 14px;
}

input.hasIcon {
    padding-left: 40px;
    width: calc(100% - 46px);
}

.input.indented {
    margin-left: 14px;
    padding-left: 19px;
    padding-right: 11px;
    margin-bottom: 18px;
}

.input.condensed {
    margin-bottom: 18px;
}

.input.superCondensed {
    margin-top: 0px;
    margin-bottom: 6px;
}

.input.justPadded {
    width: calc(100% - 30px);
    margin-left: 14px;
}

.input.last:not(.noSpaceAfter) {
    margin-bottom: 36px;
}

.mailIcon {
    position: absolute;
    height: 16px;
    top: 12px;
    left: 6px;
}

.orgIcon {
    position: absolute;
    height: 16px;
    top: 12px;
    left: 6px;
}

.titleIcon {
    position: absolute;
    height: 16px;
    top: 12px;
    left: 6px;
}

.lockIcon {
    position: absolute;
    height: 20px;
    top: 8px;
    left: 9px;
}

.webIcon {
    position: absolute;
    height: 20px;
    top: 8px;
    left: 9px;
}

.personIcon {
    position: absolute;
    height: 21px;
    top: 8px;
    left: 9px;
}

.searchIcon {
    position: absolute;
    height: 22px;
    top: 8px;
    left: 7px;
}

.searchIcon.noSpaceBefore {
    top: 4px;
}

.bulletIcon {
    position: absolute;
    height: 24px;
    top: 8px;
    left: 8px;
}

.errorBulletIcon {
    position: absolute;
    height: 18px;
    top: 10px;
    left: 11px;
}

.extraErrorIcon {
    position: absolute;
    height: 18px;
    top: 10px;
    right: 6px;
}

.boxIcon {
    position: absolute;
    height: 25px;
    top: 7px;
    left: 6px;
}

.eyeIcon {
    position: absolute;
    height: 16px;
    top: 12px;
    right: 6px;
}

.onePointIcon {
    position: absolute;
    height: 24px;
    top: 8px;
    left: 8px;
}

.input.lightPlaceHolder::placeholder {
    color: var(--color-gray);
}