.orgTable {
    border-collapse: collapse; /* Ensure borders between cells are collapsed */
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--font-p-size);
    border: 1px solid var(--color-darkgray); /* Border for the entire table */
}

.orgTable th, .orgTable td {
    border: 1px solid var(--color-darkgray); /* Border for each cell */
    padding: 10px;
    text-align: left;
}

.orgTable thead {
    font-weight: 600;
    background-color: var(--color-purple);
    color: var(--color-white);
}

.orgTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3; /* Optional: for alternate row shading */
}

.cell {
    text-wrap: wrap;
    font-size: 14px;
}

.headerIcon {
    width: 18px;
}

.activeStatus {
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--color-yellowgreen);
    padding: 2px 6px;
}

.inactiveStatus {
    font-weight: bold;
    color: var(--color-white);
    background-color: red;
    padding: 2px 6px;
}

.action {
    padding: 4px 8px;
    font-size: 16px;
    border-radius: 12px;
    color: var(--color-white);
    cursor: pointer;
    display: inline-block;
    margin-bottom: 4px;
    border: 0px solid var(--color-white);
}

.action.edit {
    background-color: var(--color-blue);
    margin-right: 6px;
}

.action.toggle {
    background-color: var(--color-purple);
}