.wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; /* Remove the default border */
    border-radius: 12px; /* Apply rounded corners to the iframe */
    background-color: red;
}

.container {
    width: 100%;
    border-bottom: 1px solid var(--color-gray);
    padding-bottom: 40px;
}

@media(max-width: 450px) {
    .container {
        width: calc(100% - 46px);
        margin: 0 auto;
        padding-bottom: 20px;
    }
}