.lineWrapper {
    position: relative;
    height: 22px;
}

.lineArt {
    position: absolute;
    top: 11px;
    width: 100%;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
    font-family: var(--font-p-family);
    padding: 4px 8px;
    color: var(--color-darkgray);
}