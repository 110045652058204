@import '../../util/vars.css';

.screenOverlay {
    position: fixed;
    width: 0%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 997;
    display: block;
    transition: background-color 0.1s ease-in-out;
}

.screenOverlay.show {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.wholeScreenWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: none;
    visibility: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.wholeScreenWrapper::-webkit-scrollbar{
    display: none;
  }

.wholeScreenWrapper.show {
    display: block;
    visibility: visible;
}

.popupWrapper {
    position: relative;
    width: calc(100% - 36px);
    max-width: 800px;
    top: 100%;
    left: 0px;
    border-radius: 12px 12px 0px 0px;
    height: auto;
    min-height: 100%;
    background-color: var(--color-white);
    z-index: 998;
    margin: 0 auto;
    /* padding: 34px 16px 28px 16px; */
    /* border: 1px solid var(--color-darkgray); */
    transition: top 0.3s ease-in-out;
    overflow: scroll;
    animation: rise-in 0.25s;
    align-content: start;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.popupWrapper::-webkit-scrollbar {
    display: none;
}

@media (min-width: 801px) {
/*     .wholeScreenWrapper {
        align-content: center;
    } */

    .popupWrapper {
        max-width: 800px;
        margin: 0 auto;
    }
}

@media (min-width: 1400px) {
    .popupWrapper {
        margin-top: 50px;
    }
}

.popupWrapper.show {
    top: 72px;
}

@keyframes rise-in {
    0% { transform: translateY(100%) }
    100% { transform: translateY(0px) }
}