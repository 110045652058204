@import '../../util/vars.css';

.navWrapper {
    height: 100%;
    min-height: 600px;
    width: 240px;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--color-white);
    z-index: 998;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
}

.navExpander {
    position: fixed;
    width: 240px;
    top: 0;
    left: -240px;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    transition: left 0.2s ease-in-out;
    white-space: nowrap;
    color: var(--color-darkgray);
}
  
.navExpander.show {
    width: 240px;
    max-width: 90%;
    left: 0px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
}

.screenOverlay {
    position: fixed;
    width: 0%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 997;
    display: block;
    transition: background-color 0.1s ease-in-out;
}

.screenOverlay.show {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.hamburger {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 32px;
    color: var(--color-white);
    cursor: pointer;
}

.navListWrapper {
    list-style-type: none;
    padding-left: 20px;
}

.linkText, .userText {
    text-decoration: none;
    color: var(--color-darkgray);
}

.popinHeader {
    height: 60px;
    width: 100% - 16px;
    color: var(--color-white);
    background-color: var(--color-darkpurple);
    font-family: var(--font-h1-family);
    font-weight: 700;
    font-size: 22px;
    position: relative;
    vertical-align: center;
    line-height: 60px;
    padding-left: 16px;
    /* border-bottom: var(--color-black) 1px solid; */
}

.popinHeader.impersonating {
    background-color: var(--color-blue);
}

.navLink {
    list-style: none;
    font-family: var(--font-p-family);
    font-size: 20px;
    padding: 12px 18px 12px 0px;
}

.centerWrapper {
    display: flex;
    align-items: center;
}

.centerWrapper.horizontalCenter {
    justify-content: center;
}

.linkText {
    padding-left: 8px;
}

.userInfoWrapper {
    position: absolute;
    bottom: 90px;
    right: 0;
    left: 0;
    background-color: var(--color-blue);
    color: var(--color-white);
    margin: 350px 15px 0px 15px;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    width: 240px - 30px;
    padding: 8px 12px;
    text-align: center;
    border-radius: 8px;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px inset;
}

.userInfoWrapper.impersonating {
    background-color: var(--color-yellow)
}

.userText {
    color: var(--color-white);
    padding-left: 6px;
}

.logoutWrapper {
    position: absolute;
    bottom: 20px;
    right: 0;
    margin-top: 2px;
    text-align: right;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.loginText {
    text-decoration: none;
    color: var(--color-black);
}

.logoutText {
    text-decoration: none;
    color: var(--color-darkpurple);
    font-size: 18px;
    cursor: pointer;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    text-align: right;
    font-family: var(--font-p-family);
}

@media (min-width: 1400px) {
    .hamburger {
        visibility: hidden;
        display: none;
    }

    .navExpander {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0px;
        height: 66px;
        overflow: hidden;
        z-index: 996;
        white-space: nowrap;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    }

    .navWrapper {
        height: 100%;
        min-height: 100%;
        width: 100%;
        position: relative;
        background-color: var(--color-darkpurple);
        color: var(--color-white);
        z-index: 995;
        overflow: hidden;
        padding: 0;
    }

    .navWrapper.impersonating {
        background-color: var(--color-blue);
    }

    .navLink.toRight {
        margin-left: 2%;
    }

    .popinHeader {
        height: 100%;
        width: 180px;
        background-color: var(--color-darkpurple);
        font-family: var(--font-h1-family);
        font-weight: 700;
        font-size: 22px;
        position: relative;
        vertical-align: center;
        line-height: 66px;
        padding-left: 16px;
        border-bottom: none;
        cursor: pointer;
    }

    .popinHeader.impersonating {
        background-color: var(--color-blue);
    }

    .logoutWrapper {
        position: absolute;
        bottom: 0px;
        height: 100%;
        line-height: 66px;
        right: 0;
        margin-top: 2px;
        text-align: right;
        padding: 0px 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
    }

    .navListWrapper {
        position: absolute;
        margin: 0 auto;
        top: 0px;
        left: 200px;
        bottom: 0px;
        list-style-type: none;
        padding: 0px 30px;
        display: flex;
        justify-content: center;
        width: calc(100% - 530px);
    }

    .linkText {
        padding: 8px;
        color: var(--color-white);
    }

    .userInfoWrapper {
        position: absolute;
        top: 12px;
        left: unset;
        right: 110px;
        bottom: 12px;
        margin: 0;
        width: fit-content;
        max-width: 170px;
        min-width: 120px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px inset;
    }

    .userText {
        padding-left: 6px;
    }

    .logoutText {
        color: var(--color-lightgray);
    }
}