.imageUpload {
    position: relative;
    width: 100%;
    height: 12rem;
    overflow: auto;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.hidden {
    opacity: 0%;
}

.imageUploadLabel {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border: 2px dashed var(--color-gray);
    color: var(--color-gray);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0px auto;
    flex-direction: column;
    line-height: 0.1rem;
}
  
  .imageUploadLabel .icon {
    font-size: 32px;
  }
  
.imageUploadLabel p {
    font-size: 20px;
}
  
.hasImage {
    display: none;
}
  
.imagePreview {
    background-color: var(--color-white);
    padding: 0 0.6rem;
    border-radius: 2px;
    text-align: center;
}
  
.imagePreview img {
    max-width: 100%;
    height: auto;
}

.resetButton {
    padding: 6px 9px;
    border-radius: 0px;
    color: var(--color-white);
    background-color: var(--color-blue);
    border: 2px var(--color-blue) solid;
    border-radius: 8px;
    font-size: 18px;
    font-family: var(--font-p-family);
    cursor: pointer;
    margin: 8px 0px 32px 0px;
}