.faqWrapper {
    width: 100%;
    border: 2px solid var(--color-orange);
    border-radius: 4px;
    margin-bottom: 50px;
}

.questionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 10px;
    align-items: center;
    background-color: var(--color-orange);
    font-family: var(--font-p-family);
    color: var(--color-white);
    cursor: pointer;
}

.explanation {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease; /* Adjust duration and easing as needed */
    transition: padding-left 0.4s ease;
    transition: padding-right 0.4s ease;
    transition: padding-top 0.4s ease;
    transition: padding-bottom 0.4s ease;
    padding: 0px;
    color: var(--color-darkgray);
}

.explanation.expanded {
    max-height: 260px; /* Set a value greater than the expected content height */
    padding: 6px 10px;
}
