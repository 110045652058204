@import '../../util/vars.css';

.headerWrapper {
    height: 131px;
    display: block;
    margin-top: 19px;
    font-size: 24px;
    font-family: var(--font-h1-family);
    font-weight: var(--font-h1-weight);
    transition: height 0.3s ease-in-out;
    color: var(--color-white);
}

.centerWrapper {
    display: flex;
    align-items: center;
    padding-left: 64px;
    cursor: pointer;
}

.centerWrapper.leftAlign {
    padding-left: 17px;
}

.headerWrapper.medium {
    height: 227px;
}

.headerWrapper.large {
    height: 300px;
}

.headerWrapper.xlarge {
    height: 345px;
}

.headerArt {
    width: 100%;
    height: 342px;
    position: absolute;
    z-index: -1;
    top: -213px;
    transition: top 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.art {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

@media (max-width: 600px) {
    .headerArt.wide {
        visibility: hidden;
        display: none;
    }
}

@media (min-width: 601px) {
    .headerArt.narrow {
        visibility: hidden;
        display: none;
    }
}

.headerArt.medium {
    top: -130px;
}

.headerArt.large {
    top: -50px;
}

.headerArt.xlarge {
    top: 0px;
}

@media (min-width: 1100px) {
    .headerArt {
        height: 576px;
    }

    .headerArt {
        top: -334px;
    }
    
    .headerArt.medium {
        top: -280px;
    }
    
    .headerArt.large {
        top: -160px;
    }
    
    .headerArt.xlarge {
        top: -120px;
    }

    .headerWrapper {
        height: 261px;
    }

    .headerWrapper.medium {
        height: 330px;
    }
    
    .headerWrapper.large {
        height: 442px;
    }
    
    .headerWrapper.xlarge {
        height: 527px;
    }

}

.loginButton {
    position: absolute;
    top: 17px;
    right: 16px;
    padding: 8px 9px 8px 14px;
    border-radius: 12px;
    color: var(--color-white);
    background-color: var(--color-darkpurple);
    border: none;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    cursor: pointer;
}

.buttonCenterWrapper {
    display: flex;
    align-items: center;
}

.studentLogout {
    position: absolute;
    top: 34px;
    right: 14px;
    padding: 4px 8px;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    color: var(--color-white);
    cursor: pointer;
    border: 2px solid var(--color-white);
    border-radius: 8px;
}

.accountInfo {
    position: absolute;
    top: 12px;
    right: 14px;
    height: 16px;
    width: 120px;
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    color: var(--color-white);
    font-weight: 300;
    text-wrap: nowrap;
    overflow: hidden;
    text-align: right;
}