.contentTable {
    border-collapse: collapse;
    width: 100%;
    font-family: var(--font-p-family);
    font-size: var(--font-p-size);
    overflow: hidden;
    border: none;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    table-layout: fixed;
    display: table;
}

.header,
.desktopHeader,
.mobileHeader {
    font-weight: 600;
    background-color: var(--color-purple);
    border: 2px solid var(--color-purple);
    color: var(--color-white);
    font-weight: bold;
    padding: 10px;
    height: 18px;
}

.cell,
.desktopCell,
.mobileCell {
    padding: 4px 10px;
    border: none;
    border-bottom: 1px solid var(--color-darkgray);
    color: var(--color-darkgray);
    overflow: hidden;
    text-overflow: ellipsis;
}

.contentTable td.last {
    border-bottom: none;
}

.contentTable td.rightBorder {
    border-right: 1px solid var(--color-gray);
}

.mobileHeader,
.mobileCell,
.mobileTime {
    display: none;
}

.actionsHeader {
    width: 120px;
}

.timeHeader {
    width: 90px;
}

@media(max-width: 600px) {
    .desktopHeader,
    .desktopCell,
    .desktopTime {
        display: none;
    }

    .mobileHeader,
    .mobileCell {
        display: table-cell;
    }

    .mobileTime {
        display: block;
    }

    .timeHeader {
        width: 36px;
    }

    .actionsHeader {
        width: 54px;
    }
}

.actionsCell,
.timeCell {
    text-align: center;
}

.timeHeader,
.actionsHeader {
    text-align: center;
}

.actionsCell button {
    display: inline-block;
    border-radius: 16px;
    width: 2.6rem;
    padding: 0.25rem 0 0 0;
    margin: 0.15rem 0.2rem;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    /*transition: background 250ms ease-in-out, transform 150ms ease;*/
}

button.approve {
    background: var(--color-yellowgreen);
    border: 2px solid var(--color-yellowgreen);
    color: var(--color-white);
    text-align: center;
}

button.deny {
    border: 2px solid #db0606;
    color: var(--color-white);
    background: #db0606;
    text-align: center;
}