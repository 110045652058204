.stimuliWrapper {
    padding: 16px 0px;
    font-style: var(--font-p-family);
    font-size: 16px;
    position: relative;
    color: var(--color-darkgray);
}

.imageWrapper {
    text-align: center;
    margin: 12px 0px;
}

.stimuliImage {
    width: 90%;
    max-width: 400px;
}

.promptWrapper {
    margin: 16px 0px 12px 0px;
    font-weight: 700;
}

.questionTextWrapper {
    margin: 12px 0px;
}

.answerRow {
    margin: 12px 0px;
    padding: 7px 10px 10px 10px;
    display: flex;
    align-items: center;
    background-color: var(--color-gray);
    color: var(--color-black);
    border-radius: 4px;
}

.answerRow.invertedColors {
    background-color: var(--color-white);
}

.answerRow.last {
    margin: 12px 0px 44px 0px;
}

.answerRow.last.invertedColors {
    margin: 12px 0px 16px 0px;
}

.iconSpace {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 8px;
}

.capstoneWrapper {
    color: var(--color-darkpurple);
    background-color: var(--color-faintpurple);
    padding: 8px 12px 1px 12px;
    border-radius: 8px;
    margin-bottom: 28px;
}

.capstoneWrapper.noChoices {
    padding-bottom: 10px;
}

.questionAndIconTextWrapper {
    display: flex;
    align-items: center;
}

.capstoneIconSpace {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 8px;
}

.questionText {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
}

.spaceAfter {
    margin-bottom: 8px;
}

.cardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    row-gap: 12px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 16px;
    min-height: 50px;
    flex-wrap: wrap;
}