.card {
    padding: 8px 28px;
    text-align: center;
}

.circle {
    background-color: var(--color-gray);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 0 auto;
}

.title,
.description {
    padding-top: 14px;
    color: var(--color-darkgray);
}

.title {
    font-size: 18px;
    font-family: var(--font-h1-family);
    color: var(--color-purple);
}

.description {
    /* font-size: var(--font-p-size); */
    font-family: var(--font-p-family);
    font-size: 15px;
    line-height: 1.4;
}

@media(max-width: 450px) {
    .circle {
        width: 120px;
        height: 120px;
        border-radius: 60px;
    }
}