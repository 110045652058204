.heroText {
    margin-top: -274px;
    margin-bottom: 100px;
    font-size: 40px;
    font-family: var(--font-h1-family);
    color: var(--color-white);
    max-width: 600px;
    height: 156px;
}

@media (min-width: 540px) {
    .heroText {
        margin-top: -270px;
        margin-bottom: 80px;
        text-align: center;
        font-size: 44px;
        max-width: 100%;
        height: 185px;
    }
}

@media (min-width: 660px) {
    .heroText {
        font-size: 52px;
    }
}

@media (min-width: 960px) {
    .heroText {
        font-size: 58px;
    }
}

@media (min-width: 1100px) {
    .heroText {
        margin-top: -410px;
        margin-bottom: 90px;
        text-align: center;
        font-size: 70px;
        max-width: 100%;
        height: 260px;
    }
}

@media (min-width: 1180px) {
    .heroText {
        font-size: 66px;
    }
}

@media (max-width: 360px) {
    .heroText {
        font-size: 32px;
    }
}