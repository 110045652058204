@import '../../util/vars.css';

.centeredWrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 27px;
}

.centeredWrapper.fitted {
    width: unset;
    float: right;
    margin-right: 7px;
}

.sliderWrapper {
    background-color: #B8B9F5;
    padding: 7px 5px;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 10px);
    max-width: 360px;
    min-width: 260px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px inset;
}

.sliderWrapper.fitted {
    max-width: unset;
    min-width: unset;
    padding: 7px 8px;
}

.sliderButton {
    background-color: #B8B9F5;
    padding: 6px 18px;
    border-radius: 6px;
    font-family: var(--font-p-family);
    font-size: 18px;
    border: 0;
    cursor: pointer;
    color: var(--color-white);
}

.sliderButton.active {
    background-color: var(--color-darkpurple);
    color: var(--color-white);
}

@media (max-width: 430px) {
    .sliderButton.fitted {
        padding: 6px 12px;
    }

    .sliderWrapper.fitted {
        padding: 7px 6px;
        gap: 2px;
    }

    .centeredWrapper.fitted {
        margin-right: 0px;
    }
}

@media (max-width: 394px) {
    .sliderButton.fitted {
        padding: 6px 8px;
    }

    .sliderWrapper.fitted {
        padding: 7px 5px;
        gap: 2px;
    }

    .centeredWrapper.fitted {
        margin-right: 0px;
    }
}

@media (max-width: 353px) {
    .sliderButton.fitted {
        padding: 6px 5px;
    }
}