.textBlock {
    font-size: var(--font-p-size);
    font-family: var(--font-p-family);
    width: 100%;
    text-align: left;
    margin: 12px 0px;
    line-height: 1.4;
}

.nospace {
    margin: 4px 0px 0px 0px;
}

.bigspace {
    margin-bottom: 64px;
}

.gray {
    color: var(--color-darkgray);
}

.lightPurple {
    color: var(--color-lightpurple);
}

.left {
    text-align: right;
}