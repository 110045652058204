.card {
    width: 300px;
    max-width: calc(100% - 30px);
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    margin: 0 auto;
    padding: 14px 14px 20px 14px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}

.name {
    position: absolute;
    background-color: var(--color-purple);
    padding: 8px 20px;
    top: -12px;
    left: -8px;
    color: var(--color-white);
    border-radius: 12px;
    font-size: 22px;
    font-family: var(--font-h1-family);
}

.logo {
    /* position: absolute; */
    width: 104px;
    height: 113px;
    padding-bottom: 9px;
    margin: 0 auto;
    padding-top: 47px;
}

.logoBig {
    /* position: absolute; */
    width: 196px;
    height: 131px;
    margin: 0 auto;
    padding-top: 38px;
}

.price {
    width: 100%;
    font-size: 40px;
    font-family: var(--font-h1-family);
    font-weight: var(--font-h1-weight);
    text-align: center;
    padding: 6px 0px 0px 0px;
    color: var(--color-darkpurple);
}

.subtext {
    color: var(--color-medgray);
    text-align: center;
    padding: 0px 0px 20px 0px;
    font-size: 14px;
}

.icon {
    width: 30px;
    font-size: 26px;
    padding: 0px 12px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-purple);
}

.feature {
    width: calc(100% - 16px);
    display: flex;
    padding: 0px 8px 16px 8px;
    color: var(--color-black);
    font-family: var(--font-p-family);
}

.description {
    width: calc(100% - 30px);
}

.feature.excluded {
    color: var(--color-gray);
}

.icon.excluded {
    color: var(--color-gray);
}

.offer {
    font-size: 14px;
    font-family: var(--font-p-family);
    line-height: 1.35;
    color: var(--color-darkgray);
    text-align: center;
}

.offer b {
    color: blue;
}