@import '../../util/vars.css';

.headerTextWrapper {
    font-family: var(--font-h1-family);
    color: var(--color-darkpurple);
    font-size: 32px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
}

.small {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: 700;
}

.left {
    float: left;
    display: inline;
    padding-top: 10px;
}

.headerTextWrapper.tight {
    margin-bottom: 10px;
}