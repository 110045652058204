.questionWrapper {
    font-style: var(--font-p-family);
    font-size: 16px;
    width: calc(100% - 10px);
    margin: 12px 0px 4px 8px;
    position: relative;
    border-radius: 4px;
}

.questionWrapper.last {
    margin-bottom: 12px;
}

.question {
    color: var(--color-black);
    padding: 2px 0px;
    position: relative;
    align-items: center;
}

.autoHeight {
    height: auto;
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
}

.flexRow {
    display: flex;
    align-items: center;
    justify-content: left;
}

.iconSpace {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 6px;
    background-color: var(--color-yellowgreen);
    width: 16px;
    height: 16px;
    border-radius: 14px;
    padding: 4px;
    color: var(--color-white);
    font-weight: 700;
}

.questionText {
    width: calc(100% - 10px);
}

.questionText.hasIcon {
    width: calc(100% - 34px);
}

.icon {
    width: 20px;
    display: flex;
    align-items: center;
}