.blockWrapper {
    height: 100px;
    line-height: 1.4;
    padding-top: 46px;
    text-align: center;
    padding-bottom: 40px;
    color: var(--color-darkgray);
    font-family: var(--font-p-family);
}

.blockWrapper.sideMargins {
    padding: 32px 16px 40px 16px;
}

.blockWrapper.noSpaceAfter {
    padding-bottom: 22px;
    height: fit-content;
}

.blockWrapper.condensed {
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 12px;
    padding-right: 12px;
}