.blockWrapper {
    height: 100px;
    line-height: 60px;
    text-align: center;
    padding: 100px 20px;
}

.assessmentCompleteArt {
    width: 120px;
}

.textBlock {
    font-size: 32px;
    font-family: var(--font-p-family);
    line-height: 1.2;
    margin-bottom: 150px;
    color: var(--color-darkgray);
}