.questionWrapper {
    font-style: var(--font-p-family);
    font-size: 16px;
    width: calc(100% - 10px);
    margin: 13px 0px 13px 8px;
    position: relative;
    border-radius: 4px;
}

.question {
    white-space: nowrap;
    background-color: var(--color-lightpurple);
    color: var(--color-white);
    padding: 6px 10px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
}

.questionText {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
}

.condenseLeft {
    padding-right: 40px;
}

.autoHeight {
    height: auto;
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
    border-radius: 4px 4px 0px 0px;
}

.sidebar {
    width: 34px;
    background-color: var(--color-darkgray);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px 4px 4px 0px;
}

.expandIcon {
    height: 22px;
    position: absolute;
    top: 4px;
    right: 6px;
    cursor: pointer;
    z-index: 2;
}

.editIcon {
    height: 19px;
    position: absolute;
    top: 36px;
    right: 7px;
    cursor: pointer;
    z-index: 2;
}

.archiveIcon {
    height: 16px;
    position: absolute;
    top: 72px;
    right: 7px;
    cursor: pointer;
    z-index: 2;
}

.answers {
    border-left: 2px solid var(--color-lightpurple);
    border-bottom: 2px solid var(--color-lightpurple);
    border-radius: 0px 0px 4px 4px;
    padding: 8px 54px 14px 20px;
    color: var(--color-darkgray);
}

.answers.standalone {
    border-right: 2px solid var(--color-lightpurple);
}

.answerRow {
    margin: 0 auto;
    padding: 6px 0px;
    display: flex;
    align-items: center;
}

.textAnswers {
    margin: 0 auto;
    padding: 6px 0px 26px 0px;
    display: flex;
    align-items: center;
}

.iconSpace {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 8px;
}

.onePointIcon {
    height: 24px;
    padding-left: 8px;
    padding-right: 6px;
    padding-bottom: 2px;
}

.rubricAnswers {
    margin: 0 auto;
    padding: 6px 0px 4px 0px;
}

.rubricRow {
    margin: 0 auto;
    padding: 4px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.anchorRow {
    margin: 0 auto;
    padding: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.anchorRow.last {
    margin-bottom: 16px;
}

.anchorLabel {
    border: 2px solid var(--color-darkpurple);
    height: 22px;
    width: 22px;
    margin: 2px 4px 2px 2px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
    font-family: var(--font-p-family);
    background-color: var(--color-darkpurple);
    color: var(--color-white);
}

.answerText {
    border: 1px solid var(--color-darkgray);
    display: flex;
    align-items: center;
    /* height: 26px; */
    padding: 5px 8px;
    margin-left: 6px;
    font-family: var(--font-p-family);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
}

.label {
    padding: 10px 0px 2px 0px;
}