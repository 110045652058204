.stimuliWrapper {
    /* border-bottom: 1px solid var(--color-medgray); */
    margin-bottom: 12px;
    padding-bottom: 40px;
    padding-top: 12px;
}

.imageWrapper {
    text-align: center;
    margin: 12px 0px;
}

.promptWrapper {
    color: var(--color-black);
    font-weight: 700;
}

.stimuliImage {
    width: 60%;
    max-width: 400px;
    margin-bottom: 8px;
}

.stimuliWrapper.last {
    border-bottom: none;
    margin-bottom: 54px;
}

.feedbackWrapper {
    border: 1px solid var(--color-darkpurple);
    padding: 9px 12px 9px 36px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    position: relative;
    overflow: hidden;
    color: var(--color-darkgray);
    font-family: var(--font-p-family);
    line-height: 1.3;
}

.infoIcon {
    background-color: var(--color-darkpurple);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 28px;
}