.contentTable {
    border-collapse: collapse;
    width: 100%;
    font-family: var(--font-p-family);
    font-size: var(--font-p-size);
    overflow: hidden;
    border: none;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    table-layout: fixed;
    display: table;
}

.contentTable thead {
    font-weight: 600;
    background-color: var(--color-purple);
    border: 2px solid var(--color-darkpurple);
    color: var(--color-white);
}

.contentTable thead tr {
    font-weight: bold;
}

.contentTable th {
    padding: 10px;
    /* border: 1px solid var(--color-white); */
}

.contentTable td {
    padding: 16px 10px;
    border: none;
    border-bottom: 2px solid var(--color-darkgray);
    color: var(--color-darkgray);
}

.contentTable td.last {
    border-bottom: none;
}

.contentTable td.rightBorder {
    border-right: 1px solid var(--color-gray);
}

.cell {
    padding: 0.1rem 0.05rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@media (min-width: 700px) {
    th.conceptHeader {
        width: 12rem;
    }

    th.actionsHeader {
        width: 8rem;
    }

    .cell {
        -webkit-line-clamp: 2; /* number of lines to show */
        line-height: 1.2;        /* fallback */
        max-height: 2.4;       /* fallback */
    }

    .forMobile {
        display: none;
    }
}

@media (max-width: 699px) {
    th.conceptHeader {
        width: 6.6rem;
    }

    th.actionsHeader {
        width: 6rem;
    }

    .cell {
        -webkit-line-clamp: 4; /* number of lines to show */
        line-height: 1.2;        /* fallback */
        max-height: 4.8;       /* fallback */
    }

    .forDesktop {
        display: none;
    }

    .forMobile {
        text-align: center;
    }

    th.misconceptionsHeader {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    th.skillsHeader {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .skillsHeader, .misconceptionsHeader, .actionsHeader {
        text-align: center;
    }
}

@media (max-width: 699px) {
    th.conceptHeader {
        width: 5.3rem;
    }
}

th .aligner {
    align-items: center;
}

.text {
    float: left;
}

.icon {
    float: right;
    margin: 0.065rem 0rem -0.3rem 0rem;
    color: var(--color-lightblack);
}

/*.contentTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.contentTable tbody tr:last-of-type {
    border-bottom: 2px solid var(--color-blue);
}*/

.actions {
    text-align: center;
}

.actions button {
    display: inline-block;
    border-radius: 16px;
    width: 2.6rem;
    padding: 0.25rem 0 0 0;
    margin: 0.15rem 0.2rem;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    /*transition: background 250ms ease-in-out, transform 150ms ease;*/
}

button.edit {
    background: var(--color-purple);
    border: 2px solid var(--color-purple);
    color: var(--color-white);
    text-align: center;
}

button.delete {
    border: 2px solid var(--color-darkpurple);
    color: var(--color-darkpurple);
    background: var(--color-faintpurple);
    text-align: center;
}