.wrapper {
    margin-bottom: 6px;
    margin-left: 0px;
    padding-left: 19px;
    padding-right: 11px;
    display: flex;
    font-family: var(--font-p-family);
}

.label {
    width: 180px;
    color: var(--color-darkpurple);
    text-align: right;
    padding-right: 6px;
    padding-top: 6px;
}

.anchors {
    width: calc(100% - 166px);
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.anchor {
    border: 2px solid var(--color-gray);
    color: var(--color-gray);
    height: 22px;
    width: 22px;
    margin: 2px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    cursor: pointer;
    user-select: none;
    font-weight: 700;
    font-family: var(--font-p-family);
}

.gray {
    padding-top: 6px;
    color: var(--color-gray);
}

.anchor.selected {
    background-color: var(--color-darkpurple);
    color: var(--color-white);
    border-color: var(--color-darkpurple);
}