.topBorder {
  border-top: 1px solid var(--color-gray);
  padding-top: 26px;
}

.rowWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: var(--font-p-family);
  font-size: var(--font-p-size);
  line-height: 1.3;
  align-items: center;
}

.imageWrapper {
  width: 160px;
  box-sizing: border-box;
  padding: 10px;
}

.textWrapper {
  box-sizing: border-box;
  padding: 10px;
  width: calc(100% - 168px);
  color: var(--color-darkgray);
}

.image {
  max-width: 160px;
  height: auto;
}

.textWrapper strong {
    color: var(--color-purple);
    font-family: var(--font-h1-family);
    font-size: 18px;
  }

.hide {
    display: none;
    visibility: hidden;
}

@media (max-width: 600px) {
  .imageWrapper,
  .textWrapper {
    flex: 1 1 100%;
    text-align: center;
    padding: 0px;
  }

  .image {
    max-width: 200px;
    margin: 0 auto;
  }

  .textWrapper strong {
    display: block;
    font-size: 22px;
    margin-bottom: 8px;
  }

  .rowWrapper {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
  }

  .hide.showSmall {
    display: block;
    visibility: visible;
  }

  .hideSmall {
    display: none;
    visibility: hidden;
  }
}