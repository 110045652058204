.progressOutline {
    border: 2px solid var(--color-darkgray);
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressBarWrapper {
    width: calc(100% - 50px);
}

.progressText {
    width: 41px;
    padding: 0px 4px 0px 9px;
    text-align: right;
    color: var(--color-darkpurple);
}

@media (min-width: 700px) {
    .progressText {
        font-weight: 600;
        margin-top: -2px;
    }
}

.progressBar {
    width: 100%;
    background-color: var(--color-purple);
}