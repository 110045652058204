.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.input {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: calc(100% - 36px);
    margin: 6px 0px 0px 0px;
    padding: 10px 0px 2px 0px;
    overflow: hidden;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
/* .input::-webkit-slider-runnable-track {
    background: var(--color-gray);
    border: 1px solid var(--color-medgray);
    height: 0.5rem;
    border-radius: 4px;
} */

/******** Firefox ********/
/* .input::-moz-range-track {
    background: var(--color-gray);
    border: 1px solid var(--color-medgray);
    height: 0.5rem;
    border-radius: 4px;
} */

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.input::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -8px; /* Centers thumb on the track */
    background-color: var(--color-darkpurple);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    /* border: 2px solid var(--color-darkpurple); */
    box-shadow: 0 0 0 3px inset var(--color-darkpurple);
    border-image: linear-gradient(90deg, var(--color-purple) 50%, var(--color-gray) 0) 0 1/calc(50% - 4px) 400px/0 calc(400px + 4px);
}

/******** Firefox ********/
.input::-moz-range-thumb {
    background-color: var(--color-white);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    /* border: 2px solid var(--color-darkpurple); */
    box-shadow: 0 0 0 3px inset var(--color-darkpurple);
    border-image: linear-gradient(90deg, var(--color-purple) 50%, var(--color-gray) 0) 0 1/calc(50% - 4px) 400px/0 calc(400px + 4px);
}

/***** Focus Styles *****/
/* Removes default focus */
.input:focus {
    outline: none;
}
  
/***** Chrome, Safari, Opera, and Edge Chromium *****/
/* .input:focus::-webkit-slider-thumb {
    outline: 2px solid var(--color-yellow);
    outline-offset: 0.125rem;
} */
  
/******** Firefox ********/
/* .input:focus::-moz-range-thumb {
    outline: 2px solid var(--color-yellow);
    outline-offset: 0.125rem;     
} */

.val {
    margin-left: 6px;
    width: 30px;
    text-align: center;
    font-family: var(--font-p-family);
    font-size: 18px;
    color: var(--color-purple);
    font-weight: 700;
    padding-top: 6px;
}

.leftLabel,
.rightLabel {
    font-family: var(--font-p-family);
    font-size: 14px;
    color: var(--color-darkpurple);
    width: 120px;
    padding-bottom: 6px;
}

.leftLabel {
    text-align: left;
    margin-right: calc(100% - 240px - 36px);
}

.rightLabel {
    text-align: right;
    margin-right: 36px;
}