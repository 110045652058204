@import '../../util/vars.css';

.selector {
    border: none;
    border: 2px solid var(--color-darkgray);
    width: calc(100% + 2px);
    margin-top: 4px;
    margin-bottom: 32px;
    font-size: 18px;
    padding: 6px 6px 6px 8px;
    color: var(--color-darkgray);
    min-height: 192px;
    resize: vertical;
    border-radius: 0;
}

.selector.short {
    min-height: 80px;
}

.selector.noSpaceAfter {
    margin-bottom: 4px;
}

@media (max-width: 480px) {
    .selector {
        min-height: 36px;
    }
}