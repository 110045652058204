@import '../../util/vars.css';

.buttonWrapper {
    width: 100%;
    background-color: var(--color-purple);/* var(--color-darkpurple); */
    color: var(--color-white);
    height: 48px;
    font-size: 16px;
    border: 0px;
    border-radius: 8px;
    font-family: var(--font-h1-family);
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.04));
}

.fitted {
    width: auto;
    padding: 0px 14px;
    margin: 12px 4px;
}

@media (min-width: 360px) {
    .fitted {
        padding: 0px 19px;
        margin: 12px 6px;
    }
}

@media (min-width: 390px) {
    .fitted {
        padding: 0px 24px;
    }
}

.alt {
    background-color: var(--color-faintpurple);
    color: var(--color-darkpurple);
    border: 2px solid var(--color-darkpurple);
}

.yellow {
    background-color: var(--color-yellow);
}

.red {
    background-color: #db0606;
}

.blue {
    background-color: var(--color-purple);
}

.altblue {
    background-color: var(--color-blue);
    color: var(--color-white);
}

.green {
    background-color: var(--color-yellowgreen);
}

.disabled {
    background-color: var(--color-gray);
    color: var(--color-white);
    cursor: not-allowed;
}