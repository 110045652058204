@import '../../util/vars.css';

.buttonRow {
    width: 100%;
    text-align: right;
    height: 20px;
}

.buttonRow.bigSpace {
    margin-top: 40px;
    right: 0;
}

.button {
    border: 0;
    background-color: var(--color-white);
    font-family: var(--font-p-family);
    color: var(--color-darkgray);
    font-size: 16px;
    margin-bottom: 0px;
    cursor: pointer;
}