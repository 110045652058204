
.misconceptionList {
    margin: 0px;
    padding-left: 26px;
}

.misconceptionBullet {
    margin: 10px 0px;
    font-family: var(--font-p-family);
    font-size: 16px;
    color: var(--color-darkgray);
    cursor: pointer;
}

.misconceptionBullet.last {
    margin-bottom: 24px;
}