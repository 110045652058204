.exportWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin-top: 12px;
    margin-bottom: 40px;
    font-family: var(--font-p-family);
}

.csvExport,
.pdfExport,
.triggerRegrade {
    /* Reset all default styles */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    background: none;
    font: inherit;
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.csvExport,
.triggerRegrade {
    cursor: pointer;
    color: var(--color-darkgray);
}

.pdfExport {
    cursor: not-allowed;
}

.triggerRegrade:disabled {
    cursor: not-allowed;
}

.exportCsvIcon,
.exportPdfIcon,
.regradeIcon {
    font-size: 22px;
    margin-right: 6px;
    padding-top: 2px;
}

.exportCsvIcon {
    color: var(--color-yellowgreen);
}

.exportPdfIcon {
    color: rgb(206, 146, 146);
}

.regradeIcon {
    color: var(--color-purple)
}

@media(max-width: 440px) {
    .hideSmall {
        display: none;
        visibility: hidden;
    }

    .exportWrapper {
        justify-content: right;
        margin-right: 10px;
    }
}