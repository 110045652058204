@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM Sans');


:root {
    --color-white: #FFFFFF;
    --color-black: #01010f;
    --color-gray: #D9D9D9;
    --color-medgray: #C8C8C8;
    --color-darkgray: #4F4F4F;
    --color-faintpurple: #C7C8FF;
    --color-lightpurple: #8184FF;
    --color-purple: #5C60F5;
    --color-darkpurple: #363AC0;
    --color-faintpink: #ffc7f6;
    --color-pink: #D049C2; /* #c036b5; */
    --color-darkpink: #88097b; /* #c036b5; */
    --color-faintblue: #add6f1;
    --color-blue: #5CC1FF;
    --color-darkblue: #3363ca;
    --color-orange: #f2903a;
    --color-yellow: #ffd900;
    --color-yellowgreen: #089b08; /* #90ce13; */
    --color-faintgreen: #9de79d; /* #90ce13; */
    --color-red: red;
    --font-h1-family: 'Roboto', sans-serif;
    --font-h1-weight: 00; /* TODO: Is this just broken? */
    --font-p-family: 'DM Sans', sans-serif;
    --font-p-size: 16px;
    --font-p-weight: 400;
}