.errorWrapper {
    font-style: italic;
    font-family: var(--font-p-family);
    font-size: 13px;
    text-align: right;
    color: var(--color-red);
}

.hidden {
    display: none;
    visibility: hidden;
}

.spaceAfter {
    margin-bottom: 30px;
}