.wrapper {
    width: 100% - 28px;
    padding: 10px 28px 10px 40px;
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
    font-size: 15px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.sideIcon {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    color: var(--color-white);
    background-color: var(--color-purple);
}

.flexWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    /* flex-wrap: wrap; */
    row-gap: 8px;
    column-gap: 12px;
    justify-content: center;
    margin-bottom: 26px;
}

.score {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    color: var(--color-darkgray);
}