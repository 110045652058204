.wrapper {
    border: 1px solid var(--color-black);
    padding: 10px 12px 12px 12px;
    margin-bottom: 51px;
}  

.wrapper.noSpace {
    margin-bottom: 6px;
}

.wrapper.noBorder {
    border: 0px solid;
    padding: 8px 2px 2px 2px;
    margin-bottom: 8px;
}

.wrapper.studentMode {
    padding: 0px;
    border: 0px;
    margin-bottom: 12px;
}

.chars {
    color: var(--color-darkgray);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
}

.chars.studentMode {
    font-size: 16px;
    margin: 16px 0px 12px 0px;
    font-weight: 700;
}

.chars:not(.static):not(.studentMode)  {
    cursor: pointer;
}

.selected,
.chars:hover:not(.static):not(.studentMode)  {
    border: 1px dashed var(--color-darkgray);
}

.green {
    background-color: rgba(8, 155, 8, 0.5);
}

.red {
    background-color: rgba(255, 0, 0, 0.5);
}

.gray {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.yellow {
    background-color: rgba(238, 255, 0, 0.4);
    cursor: pointer;
}

.chars:hover:not(.green):not(.red):not(.static):not(.studentMode) {
    background-color: rgba(0, 0, 0, 0.2);
}