.questionWrapper {
    font-style: var(--font-p-family);
    font-size: 16px;
    width: calc(100% - 10px);
    margin: 6px 0px 4px 8px;
    position: relative;
    border-radius: 4px;
}

.questionWrapper.last {
    margin-bottom: 16px;
}

.question {
    white-space: nowrap;
    color: var(--color-black);
    padding: 2px 0px;
    position: relative;
    align-items: center;
}

.autoHeight {
    height: auto;
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
}

.flexRow {
    display: flex;
    align-items: center;
    justify-content: left;
}

.iconSpace {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 6px;
    background-color: var(--color-yellowgreen);
    width: 16px;
    height: 16px;
    border-radius: 14px;
    padding: 4px;
    color: var(--color-white);
    font-weight: 700;
}

.questionText {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 10px);
    padding-right: 10px;
}

.questionText.hasIcon {
    width: calc(100% - 34px);
}

.answers {
    padding: 6px 0px 0px 20px;
    color: var(--color-darkgray);
}

.answerRow {
    margin: 0 auto;
    padding: 4px 0px;
    display: flex;
    align-items: center;
}

.globalStat {
    color: var(--color-white);
    background-color: var(--color-lightpurple);
    width: 30px;
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    text-align: center;
    border-radius: 4px;
    padding: 0px 4px 2px 4px;
}

.icon {
    width: 20px;
    display: flex;
    align-items: center;
}

.answerText {
    flex: 1;
}

.percentBubble {
    display: inline-block;
    padding: 0px 4px 2px 4px;
    width: 30px;
    text-align: center;
    background-color: var(--color-purple);
    border-radius: 4px;
    color: var(--color-white);
    font-size: 12px;
}

.textAnswers {
    margin: 0 auto;
    padding: 0px 0px 4px 0px;
}

.onePointIcon {
    height: 24px;
    padding-left: 8px;
    padding-right: 6px;
    padding-bottom: 2px;
}

.rubricAnswers {
    margin: 0 auto;
    padding: 0px 0px 8px 0px;
}

.rubricRow {
    margin: 0 auto;
    padding: 4px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.anchorRow {
    margin: 0 auto;
    padding: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.anchorRow.last {
    margin-bottom: 16px;
}

.anchorLabel {
    border: 2px solid var(--color-darkpurple);
    height: 22px;
    width: 22px;
    margin: 2px 4px 2px 2px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
    font-family: var(--font-p-family);
    background-color: var(--color-darkpurple);
    color: var(--color-white);
}

.anchorText {
    border: 1px solid var(--color-darkgray);
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0px 8px;
    margin-left: 6px;
    font-family: var(--font-p-family);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
}

.anchorText.correctDragDrop {
    background-color: green;
    color: white;
}

.anchorText.incorrectDragDrop {
    background-color: red;
    color: white;
}

.label {
    padding: 10px 0px 2px 0px;
}