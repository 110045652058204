@import '../../util/vars.css';

.assessmentWrapper {
    width: 100% - 28px;
    height: 131px;
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    margin-bottom: 12px;
    padding: 28px 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}

.assessmentWrapper.faded {
    background-color: rgb(0,0,0,0.10);
}

.assessmentWrapper.last {
    margin-bottom: 64px;
}

@media (min-width: 801px) {
    .assessmentWrapper {
        padding: 36px 22px 28px 22px;
    }
}

.assessmentName {
    font-style: var(--font-p-family);
    font-size: 18px;
    font-weight: 700;
    margin-top: 14px;
    margin-bottom: 8px;
    color: var(--color-darkpurple);
}

.assessmentDescription {
    font-family: var(--font-p-family);
    font-size: 16px;
    font-weight: var(--font-p-weight);
    height: 64px;
    overflow: hidden;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.28;        /* fallback */
    max-height: 3.84;       /* fallback */
}

.capstonesWrapper {
    font-style: var(--font-p-family);
    font-size: 16px;
    position: absolute;
    bottom: 12px;
    left: 14px;
    max-width: 40%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: var(--color-purple);
    color: var(--color-white);
    padding: 3px 8px;
    border-radius: 4px;
}

.standardsWrapper {
    font-family: var(--font-p-family);
    font-size: 16px;
    position: absolute;
    bottom: 15px;
    right: 14px;
    font-style: italic;
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-darkgray);
}

.assessmentArt {
    width: calc(100% + 28px);
    height: 60px; 
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
}




.wrapper {
    width: 100% - 28px;
    height: 182px;
    border-radius: 8px;
    border: 1px solid var(--color-medgray);
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
}

.wrapper.faded {
    background-color: rgb(0,0,0,0.10);
}

.wrapper.last {
    margin-bottom: 64px;
}

.header {
    background-color: var(--color-purple);
    height: 36px;
    color: var(--color-white);
    font-family: var(--font-h1-family);
    width: calc(100% - 154px);
    padding: 16px 150px 0px 14px;
    align-items: end;
    justify-content: left;
    font-size: 22px;
    border-bottom: 10px solid var(--color-faintpurple);
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.header.own {
    background-color: var(--color-pink);
    border-bottom: 10px solid var(--color-faintpink);
}

.header.friend {
    background-color: var(--color-yellowgreen);
    border-bottom: 10px solid var(--color-faintgreen);
}

.questions {
    background-color: var(--color-faintpurple);
    position: absolute;
    width: 110px;
    height: fit-content;
    top: 14px;
    right: 0px;
    padding: 6px 10px;
    border-radius: 12px 0px 0px 0px;
    text-align: center;
    font-family: var(--font-p-family);
    color: var(--color-darkpurple);
    font-size: 15px;
}

.questions.own {
    background-color: var(--color-faintpink);
    color: var(--color-pink);
}

.questions.friend {
    background-color: var(--color-faintgreen);
    color: var(--color-yellowgreen);
}

.description {
    padding: 12px 14px;
    font-family: var(--font-p-family);
    color: var(--color-black);
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    line-height: 1.28;        /* fallback */
    max-height: 3.84;       /* fallback */
}

.bottomRow {
    padding: 16px 12px 12px 12px;
    width: calc(100% - 24px);
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.bottomLeft,
.bottomRight {
    /* width: calc(50% - 8px); */
    font-family: var(--font-p-family);
}

.bottomLeft {
    width: calc(45% - 8px);
    display: flex;
    align-items: center;
    color: var(--color-darkgray);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
}

.owner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
}

.bottomRight {
    width: calc(55% - 8px);
    text-align: right;
    text-overflow: ellipsis;
    color: var(--color-darkgray);
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
}